<template>
    <div>
        <el-dialog v-if="selectedValue==='微信支付'" :visible="payVisible" custom-class="my-dialog" @close="$emit('closed')">
            <span class="dialog-body">
                <div>
                    <p style="font-family: Source Han Sans CN, Source Han Sans CN;font-weight: bold;font-size: 22px;color: #1A1A1A;">请您在新打开的页面上完成付款。</p>
                    <p>付款完成前请不要关闭此窗口。</p>
                    <p>完成付款后请根据您的情况点击下面的按钮：</p>
                </div>
                <div style="text-align:center;margin-top:20px;">
                    <img :src="wxPic" alt="">
                </div>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="$emit('retry')">付款遇到问题，重新支付</el-button>
                <el-button style="background:#1A9DFF;color:#fff;" @click="wxfinish">完成支付</el-button>
            </span>
        </el-dialog>
        <!-- <el-dialog v-if="selectedValue==='支付宝支付'" :visible="payVisible" width="22%" custom-class="my-dialog" @close="$emit('closed')">
            <span class="dialog-body">
                <div>
                    <p style="font-family: Source Han Sans CN, Source Han Sans CN;font-weight: bold;font-size: 22px;color: #1A1A1A;">请您在新打开的页面上完成付款。</p>
                    <p>付款完成前请不要关闭此窗口。</p>
                    <p>完成付款后请根据您的情况点击下面的按钮：</p>
                </div>
                <div style="text-align:center;margin-top:20px;">
                   <iframe style="height:210px;margin-left: 100px;" :src="aliPic" frameborder="0"></iframe>
                </div>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="$emit('retry')">付款约到问题，重新支付</el-button>
                <el-button style="background:#1A9DFF;color:#fff;" @click="alifinish">完成支付</el-button>
            </span>
        </el-dialog> -->
        <el-dialog :visible="addressVisible" :lock-scroll="false" custom-class="three-dialog" @close="$emit('closed')">
            <span class="dialog-body">
                <div>
                    <p style="font-family: Source Han Sans CN, Source Han Sans CN;font-weight: bold;font-size: 22px;color: #1A1A1A;">请选择收获地址</p>
                    <p style="font-weight: 500;font-size: 12px;color: #F92B2B;margin:10px 0;">如需新增请前往【个人中心】-【收货地址】中新增</p>
                </div>
                <div v-if="addressList.length">
                    <el-radio-group v-model="addressRadio" style="width:100%;">
                        <el-radio v-for="(item,index) in addressList" :key="index" :disabled="item.statusTitle=='待审核'||item.statusTitle=='审核驳回'" class="address" :label="item.id">
                            <div :class="{'first':index===0}">
                                <div style="display:flex;align-items:center;color:#333333;font-weight: bold;">
                                    <div>{{item.contacter}}</div>
                                    <div style="width: 0px;height: 13px;border: 1px solid #CFCFCF;margin:0 10px;"></div>
                                    <div>{{item.mobile}}</div>
                                    <div style="width: 0px;height: 13px;border: 1px solid #CFCFCF;margin:0 10px;"></div>
                                    <div v-if="item.statusTitle=='待审核'" style="color:#41b883;">{{item.statusTitle}}</div>
                                    <div v-if="item.statusTitle=='审核通过'" style="color:#1a9dff;">{{item.statusTitle}}</div>
                                    <div v-if="item.statusTitle=='审核驳回'" style="color:#F92B2B;">{{item.statusTitle}}</div>
                                    <div v-if="item.isDefault" style="width: 65px;height: 22px;background: #1A9DFF;border-radius: 12px;color:#fff;font-size: 12px;text-align: center;line-height: 22px;margin-left:10px;">默认地址</div>
                                </div>
                                <div style="font-weight: 500;font-size: 12px;color: #333333;margin-top:5px;  word-wrap: break-word; word-break: normal;">
                                    {{item.province}}{{item.city}}{{item.area}}{{item.address}}
                                </div>
                            </div>
                        </el-radio>
                    </el-radio-group>
                </div>
                <div v-else>
                    <div slot="empty" class="nodata_box">
                        <img src="../../../assets/images/index/nodata.png" />
                        <span class="txt">暂无数据哦~</span>
                    </div>
                </div>
                <!-- <div @click="arrow=!arrow">
                    <div v-if="addressList.length>5&&arrow" style="height: 36px;background: #F5F5F5;margin-top:10px;text-align:center;line-height: 36px;font-size: 12px;color: #333333;cursor: pointer;">收起
                        <i class="el-icon-arrow-up"></i>
                    </div>
                    <div v-if="addressList.length>5&&!arrow" style="height: 36px;background: #F5F5F5;margin-top:10px;text-align:center;line-height: 36px;font-size: 12px;color: #333333;cursor: pointer;">查看更多
                        <i class="el-icon-arrow-down"></i>
                    </div>
                </div> -->
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button style="width:200px;" @click="$emit('addressRetry')">取消</el-button>
                <el-button style="background:#1A9DFF;color:#fff;width:200px;" @click="sureAddress">确定</el-button>
            </span>
        </el-dialog>
        <el-dialog v-if="selectedValue==='对公打款'" title="对公打款" center="center" :visible="payVisible" custom-class="you-dialog" @close="doClose">
            <span class="dialog-body">
                <p style="font-family: Source Han Sans CN, Source Han Sans CN;font-weight: bold;font-size: 22px;color: #1A1A1A;">请完成对公打款并上传打款凭证</p>
                <div style="background: #F3F5F7;border-radius: 6px;padding:12px;margin-top:14px;font-size: 16px;color: #333333;">
                    <div style="font-weight: bold;font-size: 16px;color: #1A1A1A;margin-bottom:12px;">对公账户</div>
                    <div>开户行： 交通银行合肥南七支行</div>
                    <div>账号： 341306000018170053769</div>
                </div>
                <div style="margin-top:12px;display:flex;align-items: center;">
                    <div style="width: 6px;height: 16px;background: #1A9DFF;border-radius: 100px;"></div>
                    <div style="font-weight: bold;font-size: 16px;color: #333333;margin-left:6px;">上传凭证({{fileList.length}}/1)</div>
                </div>
                <div style="display:flex;align-items: center;margin-top:12px;">
                    <el-upload :show-file-list="false" action="" :auto-upload="false" :on-change="onChange" :file-list="fileList">
                        <div class="upload-demo" v-if="!fileList.length">
                            <img src="../../../assets/images/orderInfo/upload.svg" style=" width: 22px;height: 22px;" alt="">

                        </div>
                    </el-upload>
                    <div class="imgList">
                        <img v-for="(item,index) in fileList" :key="index" style="width:80px;height:80px;margin:0 10px;" :src="item.url" alt="">
                        <i v-if="fileList.length" @click="delImg" class="el-icon-error"></i>
                    </div>
                </div>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="$emit('retry')">付款遇到问题，重新支付</el-button>
                <el-button style="background-color:#1A9DFF;color:#fff;width:200px;" class="btn" @click="dgfinish">完成支付</el-button>
            </span>
        </el-dialog>
    </div>

</template>

<script>
export default {
    props: {
        payVisible: {
            type: Boolean,
            default: false
        },
        addressVisible: {
            type: Boolean,
            default: false
        },
        selectedValue: {
            type: String,
            default: ''
        },
        wxPic: {
            type: String,
            default: ''
        },
        aliPic: {
            type: String,
            default: ''
        },
        addressList: {
            type: Array,
            default: () => {
                return []
            }
        },
        defaultAddressId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            fileList: [],
            addressRadio: 1,
            timer: null,
            file: '',
            imageUrl: '',
            // arrow: false,
        }
    },
    mounted() {
        this.timer = setTimeout(() => {
            this.addressRadio = this.defaultAddressId
            console.log(this.defaultAddressId);
        }, 200);
    },
    beforeDestroy() {
        clearTimeout(this.timer)
    },
    methods: {

        async onChange(res, fileList) {
            console.log(fileList);
            this.file = res.raw
            const reader = new FileReader();
            reader.onload = e => {
                // 将读取的结果赋值给 imageUrl
                this.imageUrl = e.target.result;
                console.log(this.imageUrl);
                this.fileList.pop()
                this.fileList.push({
                    url: this.imageUrl,
                    name: res.name
                });
            };
            // 读取文件内容
            reader.readAsDataURL(res.raw);

        },
        handleRemove() { },
        handlePreview() { },
        delImg() {
            this.fileList = []
        },
        doClose() {
            this.$emit('closed')
            this.fileList = []
        },
        sureAddress() {

            this.$emit('addressFinish', this.addressRadio)
        },
        dgfinish() {
            if (!this.fileList.length) return this.$message.error('请上传打款凭证')
            this.$emit('finish', {
                type: 'trans',
                image: this.file
            })
        },
        wxfinish() {
            this.$emit('finish', {
                type: 'wechat',
            })
        },
        alifinish() {
            this.$emit('finish', {
                type: 'aliPay',
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.address {
    padding: 12px 0;
    border-bottom: 1px dashed #e6e6e6;
    width: 100%;
    display: flex;
}
.my-dialog {
    .dialog-body {
        // display: flex;
        // // justify-content: center;
        // flex-direction: column;
        // align-items: center;

        font-size: 14px;
        color: #a0a0a0;
        img {
            width: 172px;
            height: 172px;
        }
    }
}

.you-dialog {
    .dialog-body {
        // display: flex;
        // // justify-content: center;
        // flex-direction: column;
        // align-items: center;

        font-size: 14px;
        color: #a0a0a0;
        img {
            width: 172px;
            height: 172px;
        }
    }
}
.upload-demo {
    width: 80px;
    height: 80px;
    background: #f3f5f5;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        background: #ebeeee;
    }
}
.btn {
    &:hover {
        background: #0687fe !important;
    }
}
.imgList {
    position: relative;
    i {
        position: absolute;
        top: 2px;
        right: 1px;
        color: #c2c4c0;
        cursor: pointer;
    }
}
.first {
    margin-top: -4px;
}
</style>
<style lang="scss" >
.my-dialog {
    width: 450px;
    height: 460px;
    border-radius: 10px 10px 10px 10px !important;
    .el-dialog__footer {
        text-align: center !important;
    }
    //     .el-dialog__title {
    //     font-weight: bold !important;
    //     font-size: 25px !important;
    //     color: #1a1a1a !important;
    // }
}
.three-dialog {
    width: 500px;

    border-radius: 10px 10px 10px 10px !important;
    .el-dialog__footer {
        text-align: center !important;
    }
    .el-dialog__header {
        padding: 0 !important;
    }
    .el-radio-group {
        height: 300px;
        overflow-y: auto;
        overflow-x: hidden; /* 当内容溢出容器时隐藏 */
        word-wrap: break-word; /* 当一个单词超出容器时自动换行 */

        /*滚动条里面小方块*/
        &::-webkit-scrollbar-thumb {
            background-color: #ebeaef;
            border-radius: 10px;
        }
        /*滚动条整体样式*/
        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
        }
        /*滚动条里面轨道*/
        &::-webkit-scrollbar-track {
            background: #ffffff;
            border-radius: 10px;
        }
    }
    .el-radio {
        white-space: wrap !important;
    }

    .el-dialog__body {
        // height: 300px;
        // overflow: auto;
    }
}
.you-dialog {
    width: 500px;
    height: 520px;
    border-radius: 10px 10px 10px 10px !important;
    .el-dialog__footer {
        text-align: center !important;
        margin-top: 60px !important;
    }
    .el-dialog__title {
        font-weight: bold !important;
        font-size: 25px !important;
        color: #1a1a1a !important;
    }
}
</style>